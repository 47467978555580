import styles from "./Button.module.css";

import React from "react";

function Button({ onClick, children }) {
  return (
    <div className={styles.buttonContainer}>
      <button className={styles.button} onClick={() => onClick()}> {children} </button>
    </div>
  );
}

export default Button;
