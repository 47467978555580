import React from 'react'
import styles from "./ForgotPassword.module.css";

function LinkExpired() {
    return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            flexDirection: "column",
          }}
        >
          <img className={styles.logo} src="./logo.png" />
    
          <h1 style={{color:"black"}}>Link Expired</h1>
        </div>
      );
}

export default LinkExpired