import React from "react";
import styles from "./PasswordTextField.module.css";

function PasswordTextfield({ value, onChange, lable,shack }) {
  return (
    <div className={styles.textFieldContainer}>

    <span className={styles.lable}>{lable}</span>
      <input
        value={value}
        onChange={(e) => onChange(e)}
        type="password"
        placeholder="******"
        className={`${styles.textField} ${shack !== "" ? styles.shack : ""}`}
      />
    </div>
  );
}

export default PasswordTextfield;
